import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import loadable from '@loadable/component';

import MainLayout from '../layouts/mainLayout';
import { getAgeName } from '../components/Dashboard/Pets/petUtils';
import { createPetCreationPayload, isBrowser } from '../utils';
import { createPet } from '../services/pets.api';
import {
  getSuggestedPlan,
  getSuggestedPlan2,
} from '../services/suggestion.api';
import { storeUserDetails } from '../services/storeUserDetails';
import { Pet, PackageSuggestion, DogsAgeType } from '../services/models';
import { StatusType } from '../types';
import { createNewBasket, removeBasket, useAppDispatch } from 'state';
import { isLoggedIn } from 'services/auth.api';
import { getUserProfile } from 'services/userProfile.api';
import { graphql } from 'gatsby';

const StatusMessage = loadable(() => import('components/common/StatusMessage'));
const ButtonLoader = loadable(() => import('components/common/ButtonLoader'));
const HowItWorksSection = loadable(() =>
  import('components/Home/HowItWorksSection'),
);
const DogProfileSection = loadable(() =>
  import('components/Home/DogProfileCard/DogProfileSection'),
);
const FeedingPlanModal = loadable(() =>
  import('components/SamplingEvent/FeedingPlanModal'),
);
const DogsName = loadable(() =>
  import('components/PetSuggestion/Step-1/DogName'),
);
const LifeStage = loadable(() =>
  import('components/PetSuggestion/Step-1/Lifestage'),
);
const Weight = loadable(() => import('components/PetSuggestion/Step-2/Weight'));
const BodyType = loadable(() =>
  import('components/PetSuggestion/Step-2/BodyType'),
);
const Activity = loadable(() =>
  import('components/PetSuggestion/Step-3/Activity'),
);
const LifeStyle = loadable(() =>
  import('components/PetSuggestion/Step2/LifeStyle'),
);
const Breed = loadable(() => import('components/PetSuggestion/Step-4/Breed'));
const WeightSlider = loadable(() =>
  import('components/PetSuggestion/Step2/WeightSlider'),
);
const FeedPercentage = loadable(() =>
  import('components/PetSuggestion/Step-3/FoodPercent/FeedPercentage'),
);

export default (props: any) => {
  const { data } = props;
  const { allRestApiApiProducts } = data;
  const allProducts = (allRestApiApiProducts?.nodes || []).map(it => {
    const id = it.endpointId;
    // delete it.endpointId;
    return { ...it, id };
  });
  const dispatch = useAppDispatch();

  const [suggestion, setSuggestion] = useState({} as PackageSuggestion);
  const [petDetails, setPetDetails] = useState({} as Pet);
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    email: '',
  });

  const [feedingPlanVisibility, setFeedingPlanVisibility] = useState(false);
  const closeFeedingPlanModal = () => setFeedingPlanVisibility(false);
  const showFeedingPlanModal = () => setFeedingPlanVisibility(true);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [serverErrors, setError] = useState([]);

  const initialAgeData = {
    type: DogsAgeType.YEARS,
    value: { years: 0, months: 0 },
  };
  const [ageData, setAgeData] = useState(initialAgeData);

  useEffect(() => {
    getUserProfile().then(res => {
      const { first_name, email } = res;
      setUserLoggedIn(isLoggedIn());
      setUserDetails({ firstName: first_name, email: email });
    });
  }, []);
  useEffect(() => {
    isBrowser() &&
      window.scrollTo({
        top: 0,
        left: 100,
      });
    (async () => {
      try {
        await dispatch(removeBasket());
        await dispatch(createNewBasket());
      } catch (e) {
        console.error('Error', e);
      }
    })();
  }, []);
  const onSubmit = async values => {
    setError([]);
    try {
      setIsSubmitting(true);
      const feedingPlan = await getSuggestedPlan2({
        age: values.lifestage,
        dgwght: values.weight,
        body_type: values.bodyType,
        activity_level: values.activity,
        food_percent:values.feedPercentage,
        breed: values.breed
      });
      setSuggestion(feedingPlan);
      const petCreationPayload = {
        name: values.dogsName,
        age: values.lifestage,
        weight: values.weight,
        activity_level: values.activity,
        food_percent: values.feedPercentage,
        body_type: values.bodyType,
        breed: values.breed
      };
      // await storeUserDetails({
      //   email: userLoggedIn ? userDetails.email : values.email?.trim(),
      //   user_name: userLoggedIn ? userDetails.firstName : values.userFirstName,
      //   name: values.dogsName,
      //   age: getAgeName(ageData),
      //   activity_level: values.lifeStyle,
      //   dgwght: Number(values.weight),
      //   food_percent: Number(values.feedPercentage),
      //   breed: values.breed,
      // });

      // //TODO: create Pet and save petDetails.
      // const petCreationPayload: Pet = createPetCreationPayload(
      //   values.dogsName,
      //   getAgeName(ageData),
      //   values.lifeStyle,
      //   values.weight,
      //   ageData.type,
      //   ageData.value,
      //   values.feedPercentage,
      //   values.breed,
      // );

      // const createdPet = await createPet(petCreationPayload);
      // if (!userLoggedIn) {
      //   setUserDetails({
      //     firstName: values.userFirstName,
      //     email: values.email?.trim(),
      //   });
      // }

      setPetDetails(petCreationPayload);
      // setSuccessState(true);
      setIsSubmitting(false);
      showFeedingPlanModal();
    } catch (error) {
      setIsSubmitting(false);
      setError(error);
      isBrowser() &&
        window.scrollTo({
          top: 0,
          left: 100,
        });
    }
  };

  const validationSchema = Yup.object({
    activity: Yup.string().required(),
    bodyType: Yup.string().required(),
    dogsName: Yup.string()
      .max(20)
      .required()
      .matches(/^[-&' 0-9a-zA-Z]+$/, 'Invalid characters')
      .label("Dog's name"),
    weight: Yup.string()
      .required()
      .label('Weight'),
    lifestage: Yup.string().required(),

    // ...(!userLoggedIn
    //   ? {
    //       userFirstName: Yup.string()
    //         .required()
    //         .matches(/^[-&' 0-9a-zA-Z]+$/, 'Invalid characters')
    //         .label('First name'),
    //       email: Yup.string()
    //         .email()
    //         .trim()
    //         .required()
    //         .label('Email'),
    //     }
    //   : {}),
  });
  const isValidData = (data = {}) => {
    let newData = {...data}
    delete newData['breed']
    const val = Object.values(newData).every(value => value);
    return val;
  };
  return (
    <MainLayout withTitle>
      <div className="page-wrapper">
        <div className="add-edit-det-wrapper">
          <div className="def-w-max">
            <div className="det-content-wrapper">
              <div className="hdr text-center">
                <h2>Tell us about your Furbaby</h2>
                <p>
                  We'll use our calorie calculator to show your dog's daily
                  calorie requirement, and a personalised feeding plan to get
                  their diet just right.
                </p>
              </div>
              <Formik
                initialValues={{
                  activity: '',
                  bodyType: '',
                  dogsName: '',
                  lifestage: '',
                  weight: '',
                  // lifeStyle: 'typical',
                  // age: '',
                  feedPercentage: 100,
                  breed: ''
                  // userFirstName: '',
                  // email: '',
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  dirty,
                  isValid,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {console.log({ errors, values })}
                    {serverErrors.length !== 0 && (
                      <StatusMessage
                        message={serverErrors}
                        type={StatusType.error}
                      />
                    )}
                    <div className="su-contents">
                      <div className="main-cn">
                        <div className="su-sections">
                          <div className="su-item-margin spacer">
                            <LifeStage
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>

                          <div className="su-item-margin spacer">
                            <BodyType
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>

                          <div className="su-item-margin spacer">
                            <Activity
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>

                          <div className="su-item-margin spacer">
                          <Breed/>
                          </div>

                          <div className="su-item-margin spacer">
                            <DogsName
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>

                          <div className="su-item-margin spacer">
                            <Weight
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>

                          <div className="align-col-cen spacer">
                          <FeedPercentage
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>

                          {/* {!userLoggedIn && (
                            <div className="su-item-margin">
                              <div className="wt-lt-wrapper mgn-top-50">
                                <div className="dog-item">
                                  <div className="row">
                                    <div
                                      className="col-sm-6 mgn-bot-30"
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <Form.Group>
                                        <Form.Label>Your First Name</Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="userFirstName"
                                          placeholder=""
                                          value={values.userFirstName}
                                          onChange={handleChange}
                                          isInvalid={
                                            touched.userFirstName &&
                                            !!errors.userFirstName
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.userFirstName}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </div>
                                    <div
                                      className="col-sm-6 mgn-bot-30"
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <Form.Group controlId="formEmail">
                                        <Form.Label>
                                          Your Email Address
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="email"
                                          placeholder=""
                                          value={values.email}
                                          onChange={handleChange}
                                          isInvalid={
                                            touched.email && !!errors.email
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.email}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )} */}
                          <div className="su-btn text-center mgn-top-50">
                            {' '}
                            <ButtonLoader
                              className={`btn-def extended ${
                                !isValidData(values) ? 'disabled' : ''
                              } button-placer`}
                              type="submit"
                              text="Show Plan"
                              loading={isSubmitting}
                              // disabled={!isValidData(values)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="ingr-col-wrapper">
        <div className="container-fluid">
          <DogProfileSection />
        </div>
      </div>
      <div className="features-wrapper mgn-sm-top-100 mgn-bot-100">
        <div className="container-fluid">
          <HowItWorksSection />
        </div>
      </div>
      {feedingPlanVisibility && (
        <FeedingPlanModal
          allProducts={allProducts || []}
          suggestion={suggestion}
          petDetails={petDetails}
          closeModal={closeFeedingPlanModal}
          show={feedingPlanVisibility}
          emailingOptionEnabled={true}
          userFirstName={userDetails.firstName}
          email={userDetails.email}
        />
      )}
    </MainLayout>
  );
};
export const query = graphql`
  query {
    allRestApiApiProducts {
      nodes {
        title
        id
        endpointId
        image
        is_public
        is_subscribable
        is_suggestible
        items_in_cart_limit
        percentage
        price {
          currency
          excl_tax
          incl_tax
          tax
        }
        product_attributes {
          code
          name
          value
        }
        product_class
        product_type
        shipping_charge
        slug
        subscription_price
        weightage
        url
        description
        categories {
          id
          name
          slug
          ancestors {
            id
            name
            slug
          }
          descendants {
            id
            name
            slug
          }
        }
      }
      totalCount
    }
  }
`;
